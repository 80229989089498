input[type="range"] {
    -webkit-appearance: none;
    margin: 16px 0;
    width: 100%;
    background-color: transparent;
}
input[type="range"]:focus {
    outline: none;
}
/* TRACK */
.track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    border-radius: 4px;
}

.slider {
    /* --track-color: #3071a9;
    --track-focus-color: #367ebd;
    --track-ms-color: #2a6495; */
    /* rgb(16, 185, 129) */
    --track-color: rgba(255, 255, 255, 0.4);
    --track-focus-color: var(--track-color); /* rgb(16, 185, 129); */
    --track-ms-color: rgb(16, 185, 129);
}

input[type="range"]::-webkit-slider-runnable-track {
    @apply track;
    /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
    background: var(--track-color);
}
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: var(--track-focus-color);
}
input[type="range"]::-moz-range-track {
    @apply track;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: var(--track-color);
}

input[type="range"]::-ms-track {
    @apply track;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type="range"]::-ms-fill-lower {
    background: var(--track-ms-color);
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-fill-upper {
    background: var(--track-color);
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]:focus::-ms-fill-lower {
    background: var(--track-color);
}
input[type="range"]:focus::-ms-fill-upper {
    background: var(--track-focus-color);
}

/* THUMB */

.thumb {
    /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
    /* border: 1px solid #000000; */
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: white;
    cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
    @apply thumb;
    -webkit-appearance: none;
    margin-top: -5px;
}
input[type="range"]::-moz-range-thumb {
    @apply thumb;
}
input[type="range"]::-ms-thumb {
    @apply thumb;
}
