textarea {
    width: 300px;
    height: 800px;
}

:root {
    --mark-color: rgba(216, 111, 58, 0.4);
    --mark-border: 4px;
}

.replaceable {
    /* display: block;
    width: 32px;
    height: 32px; */
    background-color: var(--mark-color);
    /* color: #ff0000; */
}

.replaceable-start {
    border-top-left-radius: var(--mark-border);
    border-bottom-left-radius: var(--mark-border);
}

.replaceable-end {
    border-top-right-radius: var(--mark-border);
    border-bottom-right-radius: var(--mark-border);
}

.app-layout,
.app-layout .CodeMirror {
    height: 100%;
}

.app-layout .CodeMirror {
    border-radius: 0px;
}
